import { applySearchParams } from "~/utils/apply-searchparams"

export function applyLocaleToCart(url: string, lang: string) {
  const formattedURL = new URL(url)

  const searchParams = new URLSearchParams(formattedURL.searchParams)
  searchParams.set("locale", lang)

  return applySearchParams(`${formattedURL.origin}${formattedURL.pathname}`, searchParams)
}

import type { MetafieldTypes } from "@unlikelystudio/commerce-connector"

export function createMetafieldInput<T extends string>(processedKey: T, type: MetafieldTypes, value: string) {
  const [namespace, ...rest] = processedKey?.split(".") ?? []
  return {
    processedKey,
    namespace: namespace ?? null,
    key: (rest ?? [])?.join("."),
    type: type ?? null,
    value,
  }
}

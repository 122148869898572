"use client"

import { useMemo } from "react"
import { sha256 } from "js-sha256"

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import type { ExplicitAny } from "~/@types/generics"
import { useLocale } from "~/lib/i18n/hooks/useLocale"
import { getI18nKey } from "~/lib/i18n/utils/get-i18n"
import { getReferenceIdFromGID } from "~/lib/shopify/utils/id"
import { type TrackingEventKey, type TrackingEvents } from "~/providers/GTMTrackingProvider/constants"
import { useEventsBuffer } from "~/providers/GTMTrackingProvider/hooks/use-events-buffer"
import { useShouldPushEvents } from "~/providers/GTMTrackingProvider/hooks/use-should-push-events"

declare global {
  interface Window {
    //@ts-ignore
    dataLayer?: ExplicitAny[]
  }
}

export function useTracking() {
  const shouldPushEvents = useShouldPushEvents()
  const { data: customer } = useGetCustomer()

  const locale = useLocale()
  const storeMarketParam = getI18nKey(locale, "gtm_store_param")

  const setEventsBuffer = useEventsBuffer()[1]

  // Memoized the hashed email to avoid some heavy computations for SHA256
  const emailMemo = useMemo(() => (customer?.email ? sha256(`${customer?.email}`) : null), [customer?.email])

  function pushToDataLayer(data: Record<string, ExplicitAny>) {
    if (window?.dataLayer && shouldPushEvents) {
      window.dataLayer?.push({
        ...data,
        ...(data.event === "page_view"
          ? {
              user_email: emailMemo,
              user_id: getReferenceIdFromGID(customer?.id) ?? null,
            }
          : {}),
        store_code: storeMarketParam,
        locale,
      })
    } else {
      setEventsBuffer((prev) => [...prev, data])
    }
  }

  function sendEvent<TKey extends TrackingEventKey>(
    event: TKey | null,
    ...params: Record<string, null> | TrackingEvents[TKey] extends Record<string, ExplicitAny>
      ? [TrackingEvents[TKey]]
      : []
  ) {
    pushToDataLayer({ ...(event ? { event } : {}), ...params[0] })
  }

  function sendResetEvent() {
    pushToDataLayer({ ecommerce: null })
  }

  return { sendEvent, pushToDataLayer, sendResetEvent }
}

import clsx from "clsx"

import type { Nullish } from "~/@types/generics"
import Video, { type TVideo } from "~/components/ui/Video"
import { Ratio } from "~/components/abstracts/Ratio"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"

import * as css from "./styles.css"

type VideoWithRatioProps = {
  className?: string
  video: Nullish<TVideo>
  ratio: Sprinkles["ratio"]
}

function VideoWithRatio({ className, video, ratio }: VideoWithRatioProps) {
  if (!video) return null

  // Added a key here because it was keeping the same video when changing the color
  return (
    <Ratio key={video?.alt} className={className} ratio={sprinkles({ ratio: ratio })}>
      <Video className={clsx(css.video)} {...video} />
    </Ratio>
  )
}

export { VideoWithRatio }

import { useGetCustomer } from "@unlikelystudio/react-ecommerce-hooks"

import useGetCart from "~/hooks/cart/useGetCart"

// import { useCookiesConsentDone } from "~/managers/CookiesConsentManager"

export function useShouldPushEvents() {
  const { cartQueryIsComplete } = useGetCart()
  const { isFetched, fetchStatus } = useGetCustomer()
  const isIdle = fetchStatus === "idle"
  const customerQueryIsComplete = isIdle || isFetched
  // TODO: Add cookiesConsentDone inside return if you want to track events only after cookies consent
  // const cookiesConsentDone = useCookiesConsentDone()

  return cartQueryIsComplete && customerQueryIsComplete
}

import { forwardRef, type PropsWithChildren, type Ref } from "react"
import { Slot } from "@radix-ui/react-slot"
import clsx from "clsx"

import type { KeepOnlyRecordFromUnion, PropsWithClassName, RemoveRecordFromUnion } from "~/@types/generics"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { hideFrom as hideFromFn, showFrom as showFromFn, type DisplayConditions } from "~/styles/utils/show-hide"

export type StackProps = PropsWithChildren<
  PropsWithClassName<
    {
      gap?: RemoveRecordFromUnion<Sprinkles["gap"]>
      responsiveGap?: Omit<KeepOnlyRecordFromUnion<NonNullable<Sprinkles["gap"]>>, "mobile">
      direction?: Sprinkles["flexDirection"]
      asChild?: boolean
      sprinklesCss?: Omit<Sprinkles, "display" | "flexDirection" | "gap">
    } & (
      | {
          hideFrom?: DisplayConditions
          showFrom?: never
        }
      | {
          hideFrom?: never
          showFrom?: DisplayConditions
        }
    )
  >
>

function AbstractStack(
  { className, gap, responsiveGap, children, direction = "row", asChild, sprinklesCss, hideFrom, showFrom }: StackProps,
  ref: Ref<HTMLDivElement>
) {
  const Comp = asChild ? Slot : "div"

  const withResponsiveDisplay = hideFrom || showFrom

  return (
    <Comp
      ref={ref}
      className={clsx(
        className,
        showFrom && showFromFn(showFrom, "flex"),
        hideFrom && hideFromFn(hideFrom, "flex"),
        sprinkles({
          ...(!withResponsiveDisplay ? { display: "flex" } : {}),
          flexDirection: direction,
          gap: {
            mobile: gap,
            ...(responsiveGap ?? {}),
          },
          ...(sprinklesCss ?? {}),
        })
      )}
    >
      {children}
    </Comp>
  )
}

export const Stack = forwardRef(AbstractStack)

import type { UnlikelyCustomAttributes, UnlikelyImage } from "@unlikelystudio/commerce-connector"

import type { Nullish } from "~/@types/generics"
import type { TImage } from "~/components/ui/Image/_data/serializer"

export type ColorCustomAttribute = {
  image: Nullish<TImage>
  value: Nullish<string>
}

export type BundleProductItemCustomAttribute = {
  color: ColorCustomAttribute
  size: {
    value: Nullish<string>
    title: Nullish<string>
  }
}

export type MasterBundleProductCustomAttribute = {
  title: Nullish<string>
  image: Nullish<UnlikelyImage>
  id: Nullish<string>
  handle: Nullish<string>
}

type ProductCustomAttributes = typeof PRODUCT_CUSTOM_ATTRIBUTES

export const PRODUCT_CUSTOM_ATTRIBUTES = {
  HANDLE: "_handle",
  QUANTITY: "_quantity",
  SKU: "_SKU",
  IS_EMBROIDERY_PRODUCT: "_is-embroidery-product",
  UNIQ_EMBROIDERY_ATTRIBUTES: "_uniq-embroidery-attributes",
  EMBROIDERY_TEXT1: "_TEXTE1",
  EMBROIDERY_TEXT2: "_TEXTE2",
  EMBROIDERY_COLOR1: "_COULEUR1",
  EMBROIDERY_COLOR2: "_COULEUR2",
  EMBROIDERY_FONT: "_FONT",
  BUNDLE_PRODUCT: "_bundle-product",
  PRODUCT_ID: "_product-id",
  PRODUCT_COLOR: "_product-color",
  PRODUCT_URL: "_product-url",
  TRACKING_DATA: "_tracking-data",
  MASTER_BUNDLE_PRODUCT: "_master-bundle-product",
  BUNDLE_VARIANT_TITLES: "_bundle-variant-titles",
  GIFT: "_gift",
  GIFT_MESSAGE: "_gift-message",
  GIFT_MESSAGE_TO: "_gift-message-to",
  GIFT_MESSAGE_FROM: "_gift-message-from",
  COLLECTION: "_product-collection",
  IS_FROM_SHOP_THE_LOOK: "_is_from_shop_the_look",
  IS_FROM_CART_CROSS_SELL: "_is-from-cart-cross-sell",
  IS_FROM_POP_IN_CROSS_SELL: "_from_pop_in_cross_sell",
  IS_FROM_PRE_CTA_CROSS_SELL: "_from_pre_cta_cross_sell",
} as const

export function getProductCustomAttributesValue(
  customAttributes: Nullish<UnlikelyCustomAttributes>,
  key: keyof ProductCustomAttributes
) {
  return customAttributes?.find((attr) => attr.key === PRODUCT_CUSTOM_ATTRIBUTES[key])?.value ?? null
}

type CartCustomAttributes = typeof CHECKOUT_CUSTOM_ATTRIBUTES

export const CHECKOUT_CUSTOM_ATTRIBUTES = {
  CHECKOUT_LANG: "_checkout-lang",
  CHECKOUT_LOCALE: "_checkout-locale",
  CHECKOUT_MARKET: "_checkout-market",
  CHECKOUT_ORIGIN: "_checkout-origin",
  CHECKOUT_PRODUCT_TRACKING_DATA: "_checkout-product-tracking-data",
} as const

export const CHECKOUT_ORIGIN_VALUE = "headless_checkout"

export function getCheckoutCustomAttributesValue(
  customAttributes: Nullish<UnlikelyCustomAttributes>,
  key: keyof CartCustomAttributes
) {
  return customAttributes?.find((attr) => attr.key === CHECKOUT_CUSTOM_ATTRIBUTES[key])?.value ?? null
}

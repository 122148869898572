"use client"

import { useRef, type ComponentProps } from "react"
import { clsx } from "clsx"
import { useHover } from "usehooks-ts"

import type { PropsWithClassName } from "~/@types/generics"
import Icon from "~/components/abstracts/Icon"
import AbstractVideo, { type VideoRootProps } from "~/components/abstracts/Video"
import { useTranslate } from "~/providers/I18nProvider/hooks/useTranslate"

import * as css from "./styles.css"

export type TVideo = VideoRootProps & {
  playerClassName?: string
  fill?: boolean
  withControls?: boolean
  alt?: string | null
}

export type VideoProps = PropsWithClassName<TVideo>

function Video({
  className,
  playerClassName,
  fill = false,
  controls = false,
  playsInline = true,
  autoPlay = true,
  loop = true,
  muted = true,
  withControls = false,
  ...props
}: VideoProps) {
  const containerRef = useRef<HTMLDivElement>(null)
  const isHover = useHover(containerRef)

  const hasControls = withControls && !controls

  return (
    <AbstractVideo.Root {...props} {...{ playsInline, autoPlay, loop, muted }} controls={false}>
      <div ref={containerRef} className={clsx(className, css.Video)}>
        <AbstractVideo.Player className={clsx(playerClassName, css.player({ fill }))} />
        {hasControls && <Controls className={clsx(css.controls({ show: isHover }))} />}
      </div>
    </AbstractVideo.Root>
  )
}

export default Video

function Controls(props: ComponentProps<"div">) {
  const t = useTranslate()
  const muteButtonContainerRef = useRef<HTMLDivElement>(null)
  const muteButtonIsHover = useHover(muteButtonContainerRef)

  return (
    <div {...props}>
      <AbstractVideo.PlayPauseButton
        className={clsx(css.button)}
        playedChildren={<Icon name="Play" direction="right" width={15} />}
        pausedChildren={<Icon name="Pause" width={10} />}
      />
      <AbstractVideo.ProgressBar
        className={clsx(css.progressBar)}
        backgroundColor="#ffffff15"
        color="#ffffff"
        ariaLabel={t("aria_video_progress_label")}
      />
      <div ref={muteButtonContainerRef} className={clsx(css.muteButtonContainer)}>
        <AbstractVideo.MuteButton
          className={clsx(css.button)}
          mutedChildren={<Icon name="VolumeOff" direction="right" width={15} />}
          muteChildren={<Icon name="VolumeMax" direction="right" width={15} />}
        />
        <div className={clsx(css.volumeBarContainer({ show: muteButtonIsHover }))}>
          <AbstractVideo.VolumeBar
            className={clsx(css.volumeBar)}
            direction="vertical"
            backgroundColor="#ffffff20"
            color="#ffffff"
            ariaLabel={t("aria_video_volume_label")}
          />
        </div>
      </div>
    </div>
  )
}
